<template>
  <v-card>
    <v-card-text>
      <v-card-title class="flex justify-end">
        <v-btn color="info" @click="updateFormStat"> {{ isUpdating ? 'Cancel' : 'Update' }}</v-btn>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="updateInformation()">

        <v-text-field
          v-model="userProfileForm.firstName"
          :disabled="!isUpdating"
          :error-messages="errors.firstName"
          :outlined="isUpdating"
          :rules="validationRules.first_name"
          label="First Name"
          required
        />

        <v-text-field
          v-model="userProfileForm.lastName"
          :disabled="!isUpdating"
          :error-messages="errors.lastName"
          :outlined="isUpdating"
          :rules="validationRules.last_name"
          label="Last Name"
          required
        />

        <v-text-field
          v-model="userProfileForm.email"
          :disabled="!isUpdating"
          :error-messages="errors.email"
          :outlined="isUpdating"
          :rules="validationRules.email"
          label="Email"
          required
          type="email"
        />

        <v-text-field
          v-model="userProfileForm.phone"
          :disabled="!isUpdating"
          :error-messages="errors.phone"
          :outlined="isUpdating"
          :rules="validationRules.phone"
          label="Phone"
          required
          type="number"
        />

        <v-fade-transition>
          <v-card-actions v-if="isUpdating" class="flex justify-end">
            <v-btn
              :disabled="!valid"
              :loading="userProfileForm.submitting"
              color="success" large
              type="submit"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-fade-transition>

      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { updateProfile } from '@/api/user';
import Toast from '@/utils/Toast';
import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';
import {
  userEmailRule,  userFirstNameRule,
  userLastNameRule,  userPhoneNumberRule
} from '@/utils/Validation/validationRules';
import ErrorHelper from '@/utils/ErrorHelper';

export default {

  name: 'ProfileInformation',

  data: () => ({
    isUpdating: false,
    valid: true,

    userProfileForm: {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      submitting: false
    },

    errors: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },

    validationRules: {
      first_name: userFirstNameRule(),
      last_name: userLastNameRule(),
      email: userEmailRule(),
      phone: userPhoneNumberRule(),
    },

  }),
  computed: {
    ...mapGetters({
      user: 'user/data'
    }),
  },
  methods: {

    async updateInformation() {

      let data = {
        id: this.userProfileForm.id,
        first_name: this.userProfileForm.firstName,
        last_name: this.userProfileForm.lastName,
        email: this.userProfileForm.email,
        phone: this.userProfileForm.phone
      };

      this.userProfileForm.submitting = true;

      try {

        let response = await updateProfile(data);
        await this.$store.dispatch('app/fetchLoggedInUser');
        Toast.info(response.data.message || 'Profile information successfully updated');
      } catch(e) {

        ErrorHelper.mapServerError(e, (error, ex) => {
          this.errors.firstName = ex(error.firstName);
          this.errors.lastName = ex(error.lastName);
          this.errors.email = ex(error.email);
          this.errors.phone = ex(error.phone);
        })

        Toast.error(e.message || 'Something went wrong');
      }
      this.isUpdating = false;
      this.userProfileForm.submitting = false;
    },

    updateFormData() {
      this.userProfileForm.id = this.user.id;
      this.userProfileForm.firstName = this.user.first_name;
      this.userProfileForm.lastName = this.user.last_name;
      this.userProfileForm.email = this.user.email;
      this.userProfileForm.phone = this.user.phone;
    },

    updateFormStat() {

      if(!this.isUpdating) {
        this.isUpdating = !this.isUpdating;
        return;
      }

      this.updateFormData();
      this.isUpdating = !this.isUpdating;
    }

  },
  watch: {
    userProfileForm: {
      handler() {
        this.errors = {};
      },
      immediate: true,
      deep: true
    },
    user: {
      handler(data) {

        if(isEmpty(data)) {
          return;
        }

        this.userProfileForm.id = data.id;
        this.userProfileForm.firstName = data.first_name;
        this.userProfileForm.lastName = data.last_name;
        this.userProfileForm.email = data.email;
        this.userProfileForm.phone = data.phone;

      },
      immediate: true,
      deep: true
    },

  }
};
</script>

<style scoped>

</style>
